import React from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import About from './components/About'; // Ensure this import path is correct
import Navbar from './components/Navbar'; // Ensure this import path is correct
import Projects from './components/Projects'; // Ensure this import path is correct
import Contact from './components/Contact'; // Ensure this import path is correct
import Footer from './components/Footer';
import Particles from './components/Particles';

const App = () => (
  <HashRouter>
    <Navbar />  
    <Routes>
      <Route path="/" element={
        <>
          <Particles />
          <About />
          <Projects />
          <Contact />
          <Footer />
        </>
      } />
    </Routes>
  </HashRouter>
);

export default App;
