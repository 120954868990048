import React from "react";
import { CogIcon as Icon } from "@heroicons/react/24/solid";
import { projects } from "../data";

export default function Projects() {
  return (
    <section id="projects">
      <div className="container px-5 py-20 mx-auto text-center max-w-7xl">
        <div className="text-center mb-16">
          <Icon className="w-20 h-20 inline-block mb-4" />
          <h1 className="text-3xl sm:text-4xl font-bold title-font mb-2">
            Projects
          </h1>
          <p className="leading-relaxed">
            Explore my projects and their detailed subprojects below.
          </p>
        </div>
        <div className="flex flex-col gap-12">
          {projects.map((project, index) => (
            <div
              key={index}
              className="bg-white shadow-lg hover:shadow-xl rounded-lg p-6 text-left"
            >
              {/* Main Project */}
              <div className="flex flex-col md:flex-row gap-6 items-start">
                <div className="w-full md:w-1/3">
                  {/* Fixed size container for the image */}
                  <div className="relative w-full h-60">
                    <img
                      alt={project.title}
                      src={project.image}
                      className="object-contain w-full h-full rounded"
                    />
                  </div>
                </div>
                <div className="flex flex-col justify-start flex-1">
                  <h2 className="text-xl font-bold text-gray-800 mb-2">
                    {project.title}
                  </h2>
                  <h3 className="text-lg text-gray-600 font-medium mb-2">
                    {project.subtitle}
                  </h3>
                  <p className="text-sm text-gray-700 mb-2">{project.desc}</p>
                  <p className="text-sm text-gray-500 mb-4">
                    <strong>Role:</strong> {project.role}
                  </p>
                  {/* Centered "Learn More" Button */}
                  <div className="text-center mt-4">
                    <a
                      href={project.link}
                      target="_blank"
                      rel="noreferrer"
                      className="inline-block text-white bg-blue-900 hover:bg-blue-600 py-4 px-8 rounded-lg text-sm font-medium shadow-md hover:shadow-lg transition-all"
                    >
                      Learn More
                    </a>
                  </div>
                </div>
              </div>

              {/* Subprojects */}
              {project.subprojects.length > 0 && (
                <div className="mt-6">
                  <h3 className="text-lg font-semibold mb-4">
                    Selected Contributions
                  </h3>
                  <div className="flex flex-col gap-6">
                    {project.subprojects.map((sub, subIndex) => (
                      <div
                        key={subIndex}
                        className="flex flex-col md:flex-row gap-4 items-start bg-gray-50 p-4 rounded hover:shadow-lg"
                      >
                        <div className="w-full md:w-1/4">
                          {/* Fixed size container for subproject image */}
                          <div className="relative w-full h-40">
                            <img
                              alt={sub.title}
                              src={sub.image}
                              className="object-contain w-full h-full rounded"
                            />
                          </div>
                        </div>
                        <div className="flex flex-col justify-start flex-1">
                          <h4 className="text-md font-bold text-gray-800">
                            {sub.title}
                          </h4>
                          <h5 className="text-sm text-gray-600 font-medium mb-1">
                            {sub.subtitle}
                          </h5>
                          <p className="text-sm text-gray-700">{sub.desc}</p>
                          <p className="text-sm text-gray-500 mb-2">
                            <strong>Role:</strong> {sub.role}
                          </p>
                          {/* Centered "Learn More" Button for Subprojects */}
                          <div className="text-center mt-4">
                            <a
                              href={sub.link}
                              target="_blank"
                              rel="noreferrer"
                              className="inline-block text-white bg-blue-900 hover:bg-blue-600 py-2 px-6 rounded-lg text-sm font-medium shadow-md hover:shadow-lg transition-all"
                            >
                              Learn More
                            </a>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
