export const projects = [
  {
    title: "CropXR",
    subtitle: "Making crops more resilient, sustainable, and climate-adaptive through data-driven design.",
    desc:
      "Through a solid foundation of Dutch plant biology, agricultural sciences, and plant breeding, the institute adds an integrated layer of experimental research, artificial intelligence and computational modelling. The goal is to develop technology and methods to generate new crop varieties that are better adjusted to climate change and less dependent on harmful agricultural interventions.",
    image: "/img/projects/cropxr.png",
    link: "https://cropxr.org/",
    role: "Research Engineer and Consultant",
    subprojects: [],
  },
  {
    title: "ResistanceDB",
    subtitle: "Saving lives and combating antibiotic resistance.",
    desc:
      "The Calgary Bloodstream Infection Cohort (CBSIC) is a comprehensive multi-omics dataset of more than 38,000 bloodstream infections linked to detailed patient records. It is the largest microbial dataset in the world allowing research in unprecedented richness.",
    image: "/img/projects/resistancedb.png",
    link: "https://www.resistancedb.org/prime-consortium",
    role: "Data Science Lead",
    subprojects: [
      {
        title: "ProteomicsQC",
        subtitle: "Server for proteomics QC/QA pipelines",
        desc:
          "Interactive quality-control and quality-assurance pipeline server for quantitative proteomics.",
        image: "/img/projects/proteomicsqc.jpg",
        link: "https://lewisresearchgroup.github.io/ProteomicsQC/",
        role: "Lead Developer",
      },
      {
        title: "Metabolomics Integrator",
        subtitle: "Python library and web application",
        desc:
          "The Metabolomics library for Data Scientists (ms-mint), and web-application for large-scale data processing (ms-mint-app).",
        image:
          "https://github.com/LewisResearchGroup/ms-mint-app/raw/develop/docs/image/MINT-logo.jpg",
        link: "https://lewisresearchgroup.github.io/ms-mint-app/",
        role: "Lead Developer",
      },
      {
        title: "CBSIC Dashboard",
        subtitle: "Exploratory Dashboard",
        desc:
          "The Calgary BSI Cohort dashboard allows easy access to detailed epidemiological data from CBSIC data such as patient age category, sex, organism names, acquisition type, hospital, seasonal distribution of infections, outcomes and antimicrobial resistances.",
        image: "/img/projects/cbsic-dashboard.jpg",
        link: "https://www.resistancedb.org/software-tools",
        role: "Lead Developer",
      },
    ],
  },
];



export const skills = [
  {"title": "Programming"},
  {"title": "Python"},
  {"title": "Conda"},
  {"title": "Mamba"},
  {"title": "Version control"},
  {"title": "Git"},
  {"title": "Github"},
  {"title": "DVC"},
  {"title": "Containerization"},
  {"title": "Docker"},
  {"title": "Data Analysis"},
  {"title": "Pandas"},
  {"title": "Dask"},
  {"title": "Data Visualization"},
  {"title": "Matplotlib"},
  {"title": "Seaborn"},
  {"title": "Plotly"},
  {"title": "Web-Development"},
  {"title": "Django"},
  {"title": "FastAPI"},
  {"title": "React"},
  {"title": "HTML"},
  {"title": "CSS"},
  {"title": "Interactive Dashboards"},
  {"title": "Plotly-Dash"},
  {"title": "Deep Learning"},
  {"title": "PyTorch"},
  {"title": "Tensorflow"},
  {"title": "Fastai"},
  {"title": "Machine Learning"},
  {"title": "Scikit-Learn"},
  {"title": "PyCaret"},
  {"title": "Chemo-Informatics"},
  {"title": "RDKit"},
  {"title": "Network Analysis"},
  {"title": "NetworkX"},
  {"title": "Holoviews"},
  {"title": "PyVis"}
]


export const testimonials = [
    {
      title: "React Reserve",
      subtitle: "MERN Stack",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium dolore rerum laborum iure enim sint nemo omnis voluptate exercitationem eius?",
      image: "./project-1.gif",
      link: "https://reactbootcamp.com",
    },
  ];

  export const me = {
    name: 'Dr. Sören Wacker',
    role: "Research HPC Engineer @ TU Delft",
    bio: "As a Research HPC Engineer at TU Delft, I develop cutting-edge computational solutions that bridge traditional research disciplines with advanced technologies. With over 15 years of experience in High-Performance Computing (HPC), machine learning, and computational modeling, I advance large-scale scientific research across various domains.",
    background: "My academic background in physics led to a PhD in computational drug design and macromolecule simulation. Having worked in Germany, Canada, and The Netherlands, I bring international expertise in computational biology, data engineering, and AI."
  }
  
  
  
