import { me } from '../data';

export default function About() {
  return (
    <section id="about">
      <div className="container mx-auto flex lg:flex-row flex-col items-center mt-[-25%] md:mt-[0%] px-5 max-w-[1200px] min-h-[60vh]">
        <div className="w-1/2 md:w-1/3 lg:w-1/4 my-8">
          <img
            className="object-cover object-center rounded-full"
            alt="Portrait"
            src="/img/portrait.jpg"
          />
        </div>
        <div className="lg:flex-grow md:w-3/4 lg:pr-24 md:pl-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center">
          <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium">
            {me.name}
            <br className="hidden lg:inline-block" />
          </h1>
          <h2 className="leading-relaxed md:text-md lg:text-lg mb-4 font-medium">
            {me.role}
          </h2>
          <p className="leading-relaxed md:text-md lg:text-lg mb-4">
            {me.bio}
          </p>
          <p className="leading-relaxed md:text-md lg:text-lg">
            {me.background}
          </p>
        </div>
      </div>
    </section>
  );
}

