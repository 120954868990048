import React from "react";
import { ChatBubbleOvalLeftIcon as Icon } from '@heroicons/react/24/solid';

export default function Contact() {
  return (
    <section id="contact" className="relative py-20">
      <div className="container px-5 pt-20 pb-0 mx-auto text-center max-w-[768px]">
        <Icon className="w-20 inline-block mb-4" />
        <h1 className="sm:text-4xl text-3xl font-medium title-font mb-4 text-gray-900">
          I’d Love To Hear From You!
        </h1>
        <p className="text-lg text-gray-600 mb-8">
          Whether you have a question, feedback, or just want to share your thoughts, feel free to drop me a message below.
        </p>
      </div>

      <div className="container px-5 pb-10 mx-auto flex justify-center">
        <form
          name="contact"
          method="POST"
          data-netlify="true"
          className="lg:w-1/3 md:w-1/2 flex flex-col w-full mt-8 bg-white p-8 rounded-lg shadow-lg"
        >
          <input type="hidden" name="form-name" value="contact" />

          <div className="relative mb-4">
            <label htmlFor="name" className="leading-7 text-sm text-gray-600">
              Your Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              required
              className="w-full p-3 border-2 border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          <div className="relative mb-4">
            <label htmlFor="email" className="leading-7 text-sm text-gray-600">
              Your Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              required
              className="w-full p-3 border-2 border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          <div className="relative mb-4">
            <label htmlFor="message" className="leading-7 text-sm text-gray-600">
              Your Message
            </label>
            <textarea
              id="message"
              name="message"
              required
              className="w-full p-3 border-2 border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          <button
            type="submit"
            className="text-white bg-blue-900 border-0 py-2 px-6 focus:outline-none hover:bg-blue-700 rounded-lg text-lg mt-4"
          >
            Send Message
          </button>

          {/* Status Messages */}
          <p className="text-sm mt-4 text-gray-500">
            I’ll get back to you as soon as possible!
          </p>
        </form>
      </div>
    </section>
  );
}
